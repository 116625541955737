import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ApplicationUpdateService } from '@notifications/services/application-update.service';
import { HybridService } from 'app/modules/core-modules/hybrid/hybrid.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();

    public version: string;
    public currentYear: number;
    public visible = true;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly applicationUpdateService: ApplicationUpdateService,
        private readonly hybridService: HybridService
    ) { }

    public ngOnInit(): void {
        this.hybridService.isHybrid$
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe(isHybrid => {
                this.visible = !isHybrid;
                this.changeDetectorRef.detectChanges();
            });

        this.applicationUpdateService
            .getCurrentVersion()
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe(formattedVersion => {
                this.version = formattedVersion;
                this.changeDetectorRef.detectChanges();
            });
        this.currentYear = new Date().getFullYear();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}