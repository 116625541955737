import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CollaborationSpaceStoreService } from '@auth/store/services/collaboration-space-store.service';
import { ReadyMessageHandler } from "./messsage-handles/ready.message-handler";
import { HybridMessageKind, MessageHandler } from './messsage-handles/message-handler';
import { RedirectMessageHandler } from './messsage-handles/redirect.message-handler';
import { HasCandidatesMessageHandler } from './messsage-handles/has-candidates.message-handler';

const READY_INTERVAL = 50;

@Injectable({
    providedIn: 'root'
})
export class HybridService {

    handlers = new Map<HybridMessageKind, () => MessageHandler<unknown>>();

    public messages$ = new Subject<HybridMessage>();
    public isHybrid$ = new BehaviorSubject<boolean>(false);
    public isReady$ = new BehaviorSubject<boolean>(false);

    public static isHybridApp = () => window.hybridWebViewHost != null;

    constructor(
        private readonly injector: Injector,
        private readonly collaborationSpaceStoreService: CollaborationSpaceStoreService) {

        this.handlers.set('ready', () => injector.get(ReadyMessageHandler));
        this.handlers.set('redirect', () => injector.get(RedirectMessageHandler));
        this.handlers.set('has-candidates', () => injector.get(HasCandidatesMessageHandler));
    }

    start() {
        if (this.isReady$.value) {
            return;
        }

        document.body.classList.add('hybrid-mode');
        const id = setInterval(() => {
            if (!HybridService.isHybridApp()) {
                return;
            }

            clearInterval(id);
            this.initConnection();

        }, READY_INTERVAL);
    }

    initConnection() {
        window.hybridWebViewHost.onMessage = (message: HybridMessage) => {
            this.messages$.next(message);
        };

        this.messages$.subscribe(message => {
            const handler = this.handlers.get(message.kind)();

            if (handler != null) {
                handler.handle(message);

                return;
            }
        });

        window.hybridWebViewHost.sendMessage(JSON.stringify({ kind: 'ready' }));
    }

    sendMessage<T extends HybridMessage>(message: T) {
        if (window.hybridWebViewHost == null) {
            return;
        }

        window.hybridWebViewHost.sendMessage(JSON.stringify(message));
    }
}

interface HybridMessage {
    kind: HybridMessageKind;
}

declare global {
    interface Window {
        hybridWebViewHost: {
            onMessage: (message: HybridMessage) => void;
            sendMessage: (jsonMessage: string) => void;
        } | null;
    }
}