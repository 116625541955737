<div class="footer-wrap" *ngIf="visible">

    <div class="footer-content">
        <div class="">
            {{ 'FOOTER.TITLES.COPYRIGHT_1' | translate }}
            {{ currentYear }}
            {{ 'FOOTER.TITLES.COPYRIGHT_2' | translate }}
        </div>

        <div class="version" *ngIf="version != null">{{ version }}
        </div>

    </div>

</div>